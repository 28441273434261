@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@font-face {
    font-family: AudiTypeBold;
    src: url("./assets/fonts/AudiType-Bold_4.03.ttf");
}
@font-face {
    font-family: AudiTypeBoldItalic;
    src: url("./assets/fonts/AudiType-BoldItalic_4.03.ttf");
}
@font-face {
    font-family: AudiTypeExtendedBold;
    src: url("./assets/fonts/AudiType-ExtendedBold_4.03.ttf");
}
@font-face {
    font-family: AudiTypeExtendedBoldItalic;
    src: url("./assets/fonts/AudiType-ExtendedBoldItalic_4.03.ttf");
}
@font-face {
    font-family: AudiTypeExtendedItalic;
    src: url("./assets/fonts/AudiType-ExtendedItalic_4.03.ttf");
}
@font-face {
    font-family: AudiTypeExtendedNormal;
    src: url("./assets/fonts/AudiType-ExtendedNormal_4.03.ttf");
}
@font-face {
    font-family: AudiTypeItalic;
    src: url("./assets/fonts/AudiType-Italic_4.03.ttf");
}
@font-face {
    font-family: AudiTypeNormal;
    src: url("./assets/fonts/AudiType-Normal_4.03.ttf");
}
@font-face {
    font-family: AudiTypeWideBold;
    src: url("./assets/fonts/AudiType-WideBold_4.03.ttf");
}
@font-face {
    font-family: AudiTypeWideLight;
    src: url("./assets/fonts/AudiType-WideLight_4.03.ttf");
}
@font-face {
    font-family: AudiTypeWideNormal;
    src: url("./assets/fonts/AudiType-WideNormal_4.03.ttf");
}
body {
    margin: 0;
    font-family: AudiTypeNormal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/** slick **/
.slick-slide > div > div {
    display: flex;
}
.slick-list {
    padding: 0 45px !important;
}
/** carousel **/
.carousel-media .slick-list {
    padding: 0 !important;
}
.carousel-media .slick-arrow {
    bottom: -60px;
    top: unset;
    width: 30px;
    height: 30px;
}
.carousel-media .slick-next {
    right: 0;
}
.carousel-media .slick-prev {
    left: 0;
}
/** banner **/
.banner-container img {
    width: 100%;
}
